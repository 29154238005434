import { RenderRunComponent } from '@/components/runs/run-by-id.component';
import { useAutoSaveStore } from '@/stores/builder/checklist-auto-save-tracker.store';
import { useChecklistStore } from '@/stores/builder/checklist.store';
import { useChecklistStatusIndicatorsStore } from '@/stores/builder/settings/checklist-status-indicators.store';
import { Checklist } from '@/types/checklist.type';
import { Run } from '@/types/run.type';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ChecklistTree } from '../../checklist-by-id.component';

export default function ChecklistBuilderPreviewComponent({
  isBuilderPreview
}: {
  isBuilderPreview?: boolean;
}) {
  // Variables

  // States
  const [run, setRun] = useState<Run | undefined>();

  // Store
  const [checklistTree] = useChecklistStore(s => [s.checklistTree], shallow);
  const [checklistStatusIndicators] = useChecklistStatusIndicatorsStore(
    s => [s.checklistStatusIndicators],
    shallow
  );
  const [autoSavedAt] = useAutoSaveStore(s => [s.autoSavedAt], shallow);

  useEffect(() => {
    if (checklistTree) {
      formRunObject(checklistTree);
    }
  }, [checklistTree, autoSavedAt, checklistStatusIndicators]);

  function getChecklistAllTasks(
    checklistTree: ChecklistTree,
    allChecklistTasks: Checklist[]
  ) {
    if (
      checklistTree &&
      checklistTree?.children &&
      checklistTree?.children?.length > 0
    ) {
      checklistTree.children.forEach(child => {
        allChecklistTasks.push(child.checklist);

        getChecklistAllTasks(child, allChecklistTasks);
      });
    }

    return allChecklistTasks;
  }

  function formChecklistObject(checklistTree: ChecklistTree) {
    let checklistObject: Checklist | undefined = undefined;

    if (checklistTree?.checklist) {
      checklistObject = checklistTree?.checklist;
      checklistObject['checklist_status_indicators'] =
        checklistStatusIndicators || [];

      const allChecklistTasks: Checklist[] = [];
      checklistObject['checklistsByChecklistId'] = getChecklistAllTasks(
        checklistTree,
        allChecklistTasks
      );
    }

    return checklistObject;
  }

  function formRunObject(checklistTree: ChecklistTree) {
    const checklistObject = formChecklistObject(checklistTree);

    const runObject: Run = {
      id: '',
      name: checklistTree?.checklist?.title,
      checklist_version_id: checklistTree?.checklist?.id,
      checklist_version: {
        checklistByVersionChecklistId: checklistObject
      },
      run_checklist_id: '',
      run_checklists: []
    };

    setRun(runObject);
  }

  if (!run) {
    return <>Loading</>;
  }

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Preview */}
        <div className={"flex-1 " + (isBuilderPreview ? ' overflow-hidden' : ' overflow-auto')}>
          <RenderRunComponent
            runId={run?.id as string}
            run={run}
            isPreview={true}
            isPublic={false}
            isSingleResponse={false}
            isBuilderPreview={isBuilderPreview || false}
          />
        </div>
      </div>
    </>
  );
}
