import {
  GET_CHECKLIST_BY_ID_FOR_PUBLISH_CHECKLIST_QUERY,
  PUBLISH_CHECKLIST_MUTATION,
  UPDATE_CHECKLIST_MUTATION
} from '@/graphql/checklist.gql';
import { removeTypename } from '@/services/graphql/graphql';
import { useAutoSaveStore } from '@/stores/builder/checklist-auto-save-tracker.store';
import {
  ChecklistComponentMapping,
  ComponentEmbed,
  ComponentFile,
  ComponentFileGroup,
  ComponentImage,
  ComponentImageGroup,
  ComponentText
} from '@/types/chcklist-component.type';
import { Checklist } from '@/types/checklist.type';
import { ChecklistStatusIndicator } from '@/types/status-indicators.type';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CLPrimaryButton } from '../../../ui-controls';
import posthog from 'posthog-js';

interface ChecklistPublishButtonProps {
  projectId: string;
  checklistId: string;
  checklistTitle: string;
  checklistUpdatedAt: string;
  checklistVersionPublishedAt: string | undefined;
  checklistVersionId: string | undefined;
  onPublishCallback?: (checklistVersionId: string) => void;
}

export default function ChecklistPublishButtonComponent({
  projectId,
  checklistId,
  checklistTitle,
  checklistUpdatedAt,
  checklistVersionPublishedAt,
  checklistVersionId,
  onPublishCallback,
}: ChecklistPublishButtonProps) {
  // Variables
  const saveTimeout: any = useRef(null);
  const router = useRouter();

  // States
  const [updatedAt, setUpdatedAt] = useState<string | undefined>(
    checklistUpdatedAt
  );
  const [publishedAt, setPublishedAt] = useState<string | undefined>(
    checklistVersionPublishedAt
  );
  const [publishedVersionId, setPublishedVersionId] = useState<
    string | undefined
  >(checklistVersionId);
  const [isPublishing, setIsPublishing] = useState(false);
  

  // Store
  const { autoSavedAt, reset, updateUpdatedAt, updatePublishedAt } =
    useAutoSaveStore();

  // GraphQL
  const [updateChecklistMutation] = useMutation(UPDATE_CHECKLIST_MUTATION);
  const [getChecklistByIdQuery] = useLazyQuery(
    GET_CHECKLIST_BY_ID_FOR_PUBLISH_CHECKLIST_QUERY
  );
  const [publishChecklistMutation] = useMutation(PUBLISH_CHECKLIST_MUTATION);

  useEffect(() => {
    if (autoSavedAt > 0) {
      // Update auto save time
      startAutoSaveTimer();
    }
  }, [autoSavedAt]);

  useEffect(() => {
    updateUpdatedAt(updatedAt || '');
    updatePublishedAt(publishedAt || '');
  }, [updatedAt, publishedAt]);

  useEffect(() => {
    return () => {
      // AutoSave, if required
      if (saveTimeout.current) {
        // Reset
        clearTimeout(saveTimeout.current);
        saveTimeout.current = null;

        saveDetails();
      }

      // Reset values
      reset();
    };
  }, []);

  function startAutoSaveTimer() {
    // Clear any existing save timeout
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current); // clear the previous timer
    }

    // Set a new save timeout
    saveTimeout.current = setTimeout(() => {
      // Reset
      saveTimeout.current = null;

      saveDetails();
    }, 1000);
  }

  function saveDetails() {
    updateChecklistMutation({
      variables: {
        id: checklistId,
        checklist: {}
      }
    }).then(data => {
      const result = data?.data as { update_checklist_by_pk: Checklist };
      if (result?.['update_checklist_by_pk']?.updated_at) {
        setUpdatedAt(result?.['update_checklist_by_pk']?.updated_at);
      }
    });
  }

  async function fetchChecklistById() {
    // Fetch checklist details
    const { data } = await getChecklistByIdQuery({
      variables: {
        id: checklistId
      },
      fetchPolicy: 'network-only'
    });

    // Checklist found
    if (data?.checklist?.[0]) {
      const checklistData = JSON.parse(
        JSON.stringify(data?.checklist?.[0])
      ) as Checklist;

      return checklistData;
    } else {
      // Throw error
      throw new Error('Checklist not found');
    }
  }

  async function prepareChecklistForPublish(checklistData: Checklist) {
    if (checklistData && checklistData['id']) {
      // Update taskId
      checklistData['task_id'] = checklistData['id'];
      checklistData['checklistsByChecklistId']?.map(checklist => {
        checklist['task_id'] = checklist['id'];
      });

      // Create a new checklist id
      const oldChecklistId = checklistData['id'];
      checklistData['id'] = uuidv4();
      updateChildChecklistWithNewParentChecklistId(
        checklistData,
        oldChecklistId,
        checklistData['id']
      );

      // Update a checklistId
      checklistData['checklistsByChecklistId']?.map(checklist => {
        checklist['checklist_id'] = checklistData['id'];
      });
    }
  }

  function updateChildChecklistWithNewParentChecklistId(
    checklistData: Checklist,
    oldParentChecklistId: string,
    newParentChecklistId: string
  ) {
    const childChecklists = checklistData['checklistsByChecklistId']?.filter(
      checklist => checklist.parent_id === oldParentChecklistId
    );

    childChecklists?.map(checklist => {
      checklist['parent_id'] = newParentChecklistId;
    });

    childChecklists?.forEach(childChecklist => {
      if (childChecklist?.id) {
        const oldChecklistId = childChecklist['id'];
        childChecklist['id'] = uuidv4();

        updateChildChecklistWithNewParentChecklistId(
          checklistData,
          oldChecklistId,
          childChecklist['id']
        );
      }
    });
  }

  function prepareChecklistComponentMappingData(
    publishableChecklistData: Checklist,
    componentMapping: ChecklistComponentMapping[],
    componentText: ComponentText[],
    componentEmbed: ComponentEmbed[],
    componentImageGroup: ComponentImageGroup[],
    componentFileGroup: ComponentFileGroup[],
    componentImage: ComponentImage[],
    componentFile: ComponentFile[]
  ) {
    if (
      publishableChecklistData?.checklistsByChecklistId &&
      publishableChecklistData?.checklistsByChecklistId?.length > 0
    ) {
      publishableChecklistData?.checklistsByChecklistId?.forEach(checklist => {
        if (
          checklist?.checklist_component_mappings &&
          checklist?.checklist_component_mappings?.length > 0
        ) {
          checklist?.checklist_component_mappings?.forEach(
            eachComponentMapping => {
              const newComponentId = uuidv4();

              if (eachComponentMapping?.component_type === 'text') {
                const newComponent =
                  eachComponentMapping?.component_text as ComponentText;
                newComponent['id'] = newComponentId;
                componentText.push(newComponent);

                delete eachComponentMapping['component_text'];
                eachComponentMapping['component_text_id'] = newComponentId;
              } else if (eachComponentMapping?.component_type === 'embed') {
                const newComponent =
                  eachComponentMapping?.component_embed as ComponentEmbed;
                newComponent['id'] = newComponentId;
                componentEmbed.push(newComponent);

                delete eachComponentMapping['component_embed'];
                eachComponentMapping['component_embed_id'] = newComponentId;
              } else if (eachComponentMapping?.component_type === 'image') {
                // Fetch images
                if (
                  eachComponentMapping?.component_image_group
                    ?.component_images &&
                  eachComponentMapping?.component_image_group?.component_images
                    ?.length > 0
                ) {
                  eachComponentMapping?.component_image_group?.component_images.forEach(
                    imageComponent => {
                      imageComponent['component_image_group_id'] =
                        newComponentId;
                      componentImage.push(imageComponent);
                    }
                  );
                }

                if (
                  eachComponentMapping?.component_image_group?.component_images
                ) {
                  delete eachComponentMapping.component_image_group[
                    'component_images'
                  ];
                }

                // Add image group
                const newComponent =
                  eachComponentMapping?.component_image_group as ComponentImageGroup;
                newComponent['id'] = newComponentId;
                componentImageGroup.push(newComponent);

                delete eachComponentMapping['component_image_group'];
                eachComponentMapping['component_image_group_id'] =
                  newComponentId;
              } else if (eachComponentMapping?.component_type === 'file') {
                // Fetch files
                if (
                  eachComponentMapping?.component_file_group?.component_files &&
                  eachComponentMapping?.component_file_group?.component_files
                    ?.length > 0
                ) {
                  eachComponentMapping?.component_file_group?.component_files.forEach(
                    fileComponent => {
                      fileComponent['component_file_group_id'] = newComponentId;
                      componentFile.push(fileComponent);
                    }
                  );
                }

                if (
                  eachComponentMapping?.component_file_group?.component_files
                ) {
                  delete eachComponentMapping.component_file_group[
                    'component_files'
                  ];
                }

                // Add file group
                const newComponent =
                  eachComponentMapping?.component_file_group as ComponentFileGroup;
                newComponent['id'] = newComponentId;
                componentFileGroup.push(newComponent);

                delete eachComponentMapping['component_file_group'];
                eachComponentMapping['component_file_group_id'] =
                  newComponentId;
              }

              // Update checklistId
              eachComponentMapping['checklist_id'] = checklist['id'];
              componentMapping.push(eachComponentMapping);
            }
          );
        }
      });
    }
  }

  function prepareChecklistStatusIndicators(
    publishableChecklistData: Checklist,
    checklistStatusIndicators: ChecklistStatusIndicator[]
  ) {
    if (
      publishableChecklistData?.checklist_status_indicators &&
      publishableChecklistData?.checklist_status_indicators?.length > 0
    ) {
      publishableChecklistData?.checklist_status_indicators?.forEach(
        checklistStatusIndicator => {
          checklistStatusIndicator['checklist_id'] =
            publishableChecklistData?.id;
          checklistStatusIndicators.push(checklistStatusIndicator);
        }
      );
    }
  }

  async function publishChecklist() {
    setIsPublishing(true);

    const checklistData = await fetchChecklistById();

    prepareChecklistForPublish(checklistData);

    // Remove type name
    removeTypename(checklistData);

    // Component mapping data
    const componentMapping: ChecklistComponentMapping[] = [];
    const componentText: ComponentText[] = [];
    const componentEmbed: ComponentEmbed[] = [];
    const componentImageGroup: ComponentImageGroup[] = [];
    const componentFileGroup: ComponentFileGroup[] = [];
    const componentImage: ComponentImage[] = [];
    const componentFile: ComponentFile[] = [];

    prepareChecklistComponentMappingData(
      checklistData,
      componentMapping,
      componentText,
      componentEmbed,
      componentImageGroup,
      componentFileGroup,
      componentImage,
      componentFile
    );

    // Checklist status indicators
    const checklistStatusIndicators: ChecklistStatusIndicator[] = [];
    prepareChecklistStatusIndicators(checklistData, checklistStatusIndicators);

    // Create checklist
    const publishableChecklist: Checklist[] = [];
    const childChecklist = checklistData['checklistsByChecklistId'];

    // Delete child checklists
    delete checklistData['checklistsByChecklistId'];

    // Delete Status Indicators
    delete checklistData['checklist_status_indicators'];

    publishableChecklist.push(checklistData);

    childChecklist?.forEach(checklist => {
      delete checklist['checklist_component_mappings'];
      publishableChecklist.push(checklist);
    });

    // Checklist version
    const newChecklistVersionId = uuidv4();
    const newChecklistVersion = {
      id: newChecklistVersionId,
      checklist_id: checklistId,
      version_checklist_id: checklistData['id']
    };

    // Update source checklist
    const updatedSourceChecklist = {
      checklist_version_id: newChecklistVersionId,
      checklist_version_published_at: 'now()'
    };

    const publishedResult = await publishChecklistMutation({
      variables: {
        publishableChecklist: publishableChecklist,
        newChecklistVersion: newChecklistVersion,
        checklistId: checklistId,
        updatedSourceChecklist: updatedSourceChecklist,
        component_text: componentText,
        component_image_group: componentImageGroup,
        component_file_group: componentFileGroup,
        component_embed: componentEmbed,
        component_images: componentImage,
        component_files: componentFile,
        checklist_component_mapping: componentMapping,
        checklist_status_indicators: checklistStatusIndicators
      }
    });

    if (publishedResult?.data?.update_checklist_by_pk) {
      // Capture the event
      posthog.capture?.('Checklist Published', { 
        eventDate: new Date().toISOString().split('T')[0],
      });
    }

    // Refresh the UI
    if (publishedResult?.data?.update_checklist_by_pk) {
      const updatedResult = publishedResult?.data?.update_checklist_by_pk;
      if (updatedResult?.updated_at) {
        setUpdatedAt(updatedResult?.updated_at);
      }

      if (updatedResult?.checklist_version_published_at) {
        setPublishedAt(updatedResult?.checklist_version_published_at);
      }

      if (updatedResult?.checklist_version_id) {
        setPublishedVersionId(updatedResult?.checklist_version_id);
      }

      // Trigger callback
      if (onPublishCallback && updatedResult?.checklist_version_id) {
        onPublishCallback(updatedResult?.checklist_version_id);
      }
    }

    setIsPublishing(false);
  }

  return (
    <>
      {/* Publish */}
      {!publishedAt || (updatedAt && updatedAt > publishedAt) ? (
        <CLPrimaryButton
          className="whitespace-nowrap"
          onClick={() => {
            publishChecklist();
          }}
          disabled={isPublishing}
        >
          {isPublishing ? 'Publishing' : 'Publish'}
        </CLPrimaryButton>
      ) : (
        <CLPrimaryButton className="whitespace-nowrap hidden sm:inline-block" disabled={true}>
          Publish
        </CLPrimaryButton>
      )}
    </>
  );
}
