import { GET_CHECKLIST_VERSION_BY_ID_QUERY } from '@/graphql/checklist.gql';
import { CREATE_RUN_AND_START_RUN_MUTATION } from '@/graphql/runs.gql';
import { Checklist } from '@/types/checklist.type';
import { RunChecklist } from '@/types/run.type';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { useUserId } from '@nhost/nextjs';
import { useRouter } from 'next/router';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import {
  CLPrimaryButton,
  CLPrimaryDate,
  CLPrimaryInput,
  CLSecondaryButton
} from '../ui-controls';

import { v4 as uuidv4 } from 'uuid';
import CLButton from '../ui-controls/default-ui-controls/button';
import { LoadingSpinWhiteCustom } from '../utils/loading.component';
import { useOrganizationStore } from '@/stores/organization.store';
import CLTooltip from '../utils/tooltip';
import { shallow } from 'zustand/shallow';
import PlanAndBillingPopupComponent from '../utils/plan-and-billing-popup.component';
import posthog from 'posthog-js';

interface PopupProps {
  checklistId: string;
  checklistVersionId: string | undefined;
  runName?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export type ChecklistAndChildren = Checklist & {
  checklistsByChecklistId: Checklist[];
};

export default function CreateRunPopupComponent({
  setIsOpen,
  ...props
}: PopupProps) {
  const router = useRouter();

  // Nhost hooks
  const userId = useUserId();

  // States
  const [runName, setRunName] = useState<string>('');
  const [dueDate, setDueDate] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [isPlanAndBillingPopupOpen, setIsPlanAndBillingPopupOpen] =
  useState<boolean>(false);

  //Store
  const [selectedOrganization, organizationUsage] = useOrganizationStore(
    state => [state.selectedOrganization, state.organizationUsage],
    shallow
  );

  // GraphQL
  const [getChecklistVersionByIdQuery] = useLazyQuery(
    GET_CHECKLIST_VERSION_BY_ID_QUERY
  );
  const [createRunAndStartRunMutation] = useMutation(
    CREATE_RUN_AND_START_RUN_MUTATION
  );

  useEffect(() => {
    if (props.isOpen) {
      setErrorMsg('');
      if (!dueDate) {
        setDueDate(new Date().toLocaleDateString('en-CA'));
      }
    }

    setRunName(props.runName || '');
  }, [props.runName, props.isOpen]);

  useEffect(() => {
    checkRunLimitation();
  }, []);

  function validate(): string | void {
    if (!runName) {
      return 'Please enter run name';
    }
  }

  async function startRun() {
    // Reset
    setErrorMsg('');
    setIsSaving(true);

    // Validation
    const errorMessage = validate();
    if (errorMessage) {
      setErrorMsg(errorMessage);
      setIsSaving(false);
      return;
    }

    // Fetch checklist version details
    const { data } = await getChecklistVersionByIdQuery({
      variables: {
        id: props?.checklistVersionId,
        checklistId: props?.checklistId
      },
      fetchPolicy: 'network-only'
    });

    // Checklist version found
    if (data?.['checklist_version']?.[0]) {
      const checklistData = data?.['checklist_version'][0][
        'checklistByVersionChecklistId'
      ] as Checklist;

      createRun(checklistData);
    } else {
      // Checklist not found
      setErrorMsg('Checklist is not found.');
      setIsSaving(false);
    }
  }

  async function createRun(checklistData: Checklist) {
    const runId = uuidv4();
    const runChecklistId = uuidv4();

    // Create a Run Checklist
    const runChecklist = getVersionRunChecklistObject(
      runId,
      runChecklistId,
      checklistData
    );

    // Create a Run
    const { data: _runDetails, extensions: _projectExtentions } =
      await createRunAndStartRunMutation({
        variables: {
          runs: [
            {
              id: runId,
              checklist_id: props?.checklistId,
              checklist_version_id: props?.checklistVersionId,
              name: runName,
              due_at: dueDate
            }
          ],
          runsAssignment: [
            {
              run_id: runId,
              user_id: userId
            }
          ],
          runChecklist: runChecklist,
          runId: runId,
          runChecklistId: runChecklistId
        }
      });

    if (_runDetails?.['insert_runs']) {
      // Capture the event
      posthog.capture?.('Run Created', { 
        eventDate: new Date().toISOString().split('T')[0],
      });
    }

    if (_runDetails?.['insert_runs']) {
      // Created successfully

      // Take to runs page
      router.push('/runs/' + runId);
    }
  }

  function getVersionRunChecklistObject(
    runId: string,
    runChecklistId: string,
    checklistData: Checklist
  ) {
    const runChecklist: RunChecklist[] = [];

    if (checklistData?.task_id) {
      runChecklist.push({
        id: runChecklistId,
        task_id: checklistData?.task_id,
        run_id: runId,
        run_checklist_id: runChecklistId,
        assigned_by: userId
      });
    }

    if (
      checklistData?.checklistsByChecklistId &&
      checklistData?.checklistsByChecklistId?.length > 0
    ) {
      checklistData?.checklistsByChecklistId?.forEach(eachChecklist => {
        runChecklist.push({
          task_id: eachChecklist?.task_id,
          run_id: runId,
          run_checklist_id: runChecklistId,
          assigned_by: userId
        });
      });
    }

    return runChecklist;
  }

  async function checkRunLimitation() {
    if (
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_plan_type?.is_freeplan &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations.length > 0
    ) {
      const runChecklistLimitation =
        selectedOrganization?.pricing_subscription?.pricing_plan?.pricing_limitations.find(
          limitation => limitation.type === 'runs'
        );
      if (
        runChecklistLimitation &&
        runChecklistLimitation?.value &&
        organizationUsage.runs &&
        organizationUsage.runs >= parseInt(runChecklistLimitation?.value)
      ) {
        return setShowUpgrade(true);
      } else {
        return setShowUpgrade(false);
      }
    }
  }

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Header */}
                  <div className="flex justify-between items-center">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Run checklist
                      </Dialog.Title>
                    </div>

                    <div>
                      <CLButton
                        className="p-2"
                        tabIndex={-1}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                            fill="black"
                            fillOpacity="0.5"
                          />
                        </svg>
                      </CLButton>
                    </div>
                  </div>

                  {/* Body */}
                  <div>
                    <div className="mt-4">
                      <CLPrimaryInput
                        type="text"
                        label="Run name *"
                        value={runName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setRunName(event.target.value);
                        }}
                      />
                    </div>

                    {/* Due date */}
                    <div className="mt-4">
                      <CLPrimaryDate
                        type="date"
                        label="Due date"
                        value={dueDate ? dueDate.substring(0, 10) : ''}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.value) {
                            setDueDate(
                              new Date(event.target.value).toISOString()
                            );
                          } else {
                            setDueDate('');
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* Footer */}
                  <div className="mt-4 flex justify-between">
                    <div>
                      {/* Error message */}
                      {errorMsg && (
                        <div className="mt-2 text-red-400">{errorMsg}</div>
                      )}
                    </div>

                    <div className="flex gap-2">
                      <CLSecondaryButton
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </CLSecondaryButton>

                      {showUpgrade ? (
                        // <CLTooltip position="top" content="Upgrade to Run">
                        <CLPrimaryButton
                          onClick={() => {
                            setIsPlanAndBillingPopupOpen(true)
                          }}
                        >
                          Upgrade to Run
                        </CLPrimaryButton>
                      ) : (
                        // </CLTooltip>
                        <CLPrimaryButton
                          className="gap-2"
                          disabled={isSaving}
                          onClick={() => {
                            startRun();
                          }}
                        >
                          {isSaving && (
                            <LoadingSpinWhiteCustom className="w-4 h-4"></LoadingSpinWhiteCustom>
                          )}
                          <svg
                            className="w-[10px]"
                            viewBox="0 0 12 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.491 15.8165C1.16427 16.0484 0.834076 16.0605 0.500432 15.8527C0.166811 15.6448 0 15.3284 0 14.9034V1.09659C0 0.671599 0.166811 0.355185 0.500432 0.147346C0.834076 -0.0605164 1.16427 -0.0484538 1.491 0.183534L11.5563 7.13706C11.8521 7.34492 12 7.63256 12 7.99999C12 8.36744 11.8521 8.65509 11.5563 8.86292L1.491 15.8165ZM1.52192 13.8262L10.0396 7.99999L1.52192 2.17379V13.8262Z"
                              fill="#444444"
                            />
                          </svg>
                          Run
                        </CLPrimaryButton>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <PlanAndBillingPopupComponent
        isOpen={isPlanAndBillingPopupOpen}
        setIsOpen={setIsPlanAndBillingPopupOpen}
      ></PlanAndBillingPopupComponent>
    </>
  );
}
