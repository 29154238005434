import PERMISSIONS_CONSTANTS from '@/constants/permission-constants';
import { useOrganizationStore } from '@/stores/organization.store';
import { useProjectsStore } from '@/stores/projects.store';
import { Menu, Transition } from '@headlessui/react';
import { useSignOut, useUserData } from '@nhost/nextjs';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import CreateProjectPopupComponent from '../projects/create-project-popup.component';
import FeedbackSurveyPopupComponent from './feedback-survey-popup.component';
import CLTooltip from '../utils/tooltip';
import PlanAndBillingPopupComponent from '../utils/plan-and-billing-popup.component';

export default function DashboardOrganizationDropdown() {
  // Variables
  const router = useRouter();
  const userData = useUserData();
  const {
    selectedOrganization,
    organizations,
    setSelectedOrganization,
    organizationPermissions
  } = useOrganizationStore();
  const [resetProjects, projects] = useProjectsStore(
    s => [s.resetProjects, s.projects],
    shallow
  );

  // Nhost hooks
  const { signOut } = useSignOut();

  // States
  const [isFeedbackSurveyPopupOpen, setIsFeedbackSurveyPopupOpen] =
    useState<boolean>(false);
  const [isCreateProjectPopupOpen, setIsCreateProjectPopupOpen] =
    useState<boolean>(false);
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [isPlanAndBillingPopupOpen, setIsPlanAndBillingPopupOpen] =
  useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const script = document.createElement('script');

      script.text = `(function(C, A, L) {
          let p = function(a, ar) {
              a.q.push(ar);
          };

          let d = C.document;
          C.Cal = C.Cal || function() {
              let cal = C.Cal;
              let ar = arguments;
              if (!cal.loaded) {
                  cal.ns = {};
                  cal.q = cal.q || [];
                  d.head.appendChild(d.createElement("script")).src = A;
                  cal.loaded = true;
              }
              if (ar[0] === L) {
                  const api = function() {
                      p(api, arguments);
                  };
                  const namespace = ar[1];
                  api.q = api.q || [];
                  typeof namespace === "string" ? (cal.ns[namespace] = api) && p(api, ar) : p(cal, ar);
                  return;
              }
              p(cal, ar);
          };
        })(window, "https://app.cal.com/embed/embed.js", "init");
        
        Cal("init", {
            origin: "https://app.cal.com"
        });`;

      document.body.appendChild(script);

      // Cleanup on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      checkProjectLimitation();
    }
  }, [projects]);

  function checkProjectLimitation() {
    if (
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_plan_type?.is_freeplan &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations.length > 0
    ) {
      const runChecklistLimitation =
        selectedOrganization?.pricing_subscription?.pricing_plan?.pricing_limitations.find(
          limitation => limitation.type === 'projects'
        );
      if (
        runChecklistLimitation &&
        runChecklistLimitation?.value &&
        projects.length &&
        projects.length >= parseInt(runChecklistLimitation?.value)
      ) {
        return setShowUpgrade(true);
      } else {
        return setShowUpgrade(false);
      }
    }
  }

  return (
    <div className="w-full">
      <Menu as="div" className="w-full relative inline-block text-left text-sm">
        <Menu.Button
          className="inline-flex w-full items-center gap-2
            pl-2 pr-3 py-3
            hover:bg-slate-100
            focus-visible:ring-2 
            focus-visible:ring-white 
            focus-visible:ring-opacity-75"
        >
          {/* Org image */}
          <div className="pl-2">
            {/* <img
              className="w-7 h-7 rounded-full mx-auto bg-slate-300"
              src={userData?.avatarUrl}
              alt=""
              width="384"
              height="512"
            /> */}

            {/* First letter */}
            <div className="w-8 h-8 text-lg flex items-center justify-center rounded-lg bg-gray-200 text-gray-600 uppercase">
              {selectedOrganization?.name?.charAt(0)}
            </div>
          </div>

          {/* Org name */}
          <div className="overflow-hidden">
            <div className="text-left truncate">
              {selectedOrganization?.name}
            </div>
            <div className="text-left text-xs truncate opacity-60">
              {
                selectedOrganization?.pricing_subscription?.pricing_plan
                  ?.pricing_plan_type?.name
              }
            </div>
          </div>

          {/* Three dots */}
          <div className="ml-auto flex w-5 h-5 items-center justify-center rounded-full hover:bg-slate-100">
            <svg
              width="3"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM4 8C4 9.10457 3.10457 10 2 10C0.895431 10 0 9.10457 0 8C0 6.89543 0.895431 6 2 6C3.10457 6 4 6.89543 4 8ZM2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.895431 12 0 12.8954 0 14C0 15.1046 0.895431 16 2 16Z"
                fill="black"
                fillOpacity="0.4"
              />
            </svg>
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute left-2 mt-2 w-56 origin-top-right 
              divide-y divide-gray-100 rounded-sm bg-white shadow-lg 
              ring-1 ring-black ring-opacity-5 focus:outline-none z-10 max-h-[80vh] overflow-auto"
          >
            <div>
              {/* Email */}
              {userData && userData?.email && (
                <div className="opacity-50 text-xs p-2">{userData?.email}</div>
              )}

              {/* List of organization */}
              {organizations && organizations?.length > 0 && (
                <div className="flex flex-col">
                  {organizations.map((organization, index) => (
                    <Menu.Item key={index}>
                      {({ active, close }) => (
                        <button
                          className="text-left px-3 py-2 hover:bg-slate-100 cursor-pointer"
                          onClick={() => {
                            if (
                              organization?.id &&
                              selectedOrganization?.id != organization?.id
                            ) {
                              resetProjects();
                              setSelectedOrganization(organization?.id);
                              router.push('/projects');
                            }
                          }}
                        >
                          <div className="flex items-center gap-2">
                            {/* Logo */}
                            <div className="">
                              {/* If Org image is present, we need to show org img */}

                              {/* First letter */}
                              <div className="w-8 h-8 text-lg flex items-center justify-center rounded-lg bg-gray-200 text-gray-600 uppercase">
                                {organization?.name?.charAt(0)}
                              </div>
                            </div>

                            {/* Details */}
                            <div className="flex-1">
                              <div className="line-clamp-1">
                                {organization?.name}
                              </div>
                              <div className="text-xs opacity-60 line-clamp-1">
                                {
                                  organization?.pricing_subscription
                                    ?.pricing_plan?.pricing_plan_type?.name
                                }
                              </div>
                            </div>

                            {/* Selected tick */}
                            <div className="w-4">
                              {selectedOrganization?.id == organization?.id && (
                                <svg viewBox="0 0 14 14">
                                  <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon>
                                </svg>
                              )}
                            </div>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              )}
            </div>

            <div className="px-1 py-1">
              {organizationPermissions[
                PERMISSIONS_CONSTANTS.MANAGE_BILLING
              ] && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? '' : 'text-black/[.6]'
                      } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                      onClick={() => {
                        router.push('/settings/plan-billing');
                      }}
                    >
                      Billing
                    </button>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? '' : 'text-black/[.6]'
                    } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                    onClick={() => {
                      router.push('/settings/members');
                    }}
                  >
                    Organization Members
                  </button>
                )}
              </Menu.Item>

              {/* New project */}
              {organizationPermissions?.[
                PERMISSIONS_CONSTANTS.CREATE_PROJECT
              ] &&
                (showUpgrade ? (
                  <CLTooltip content="Upgrade To Create Project">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? '' : 'text-black/[.6]'
                          } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                          onClick={() => {
                            setIsPlanAndBillingPopupOpen(true)
                          }}
                        >
                          New Project
                        </button>
                      )}
                    </Menu.Item>
                  </CLTooltip>
                ) : (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? '' : 'text-black/[.6]'
                        } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                        onClick={() => {
                          setIsCreateProjectPopupOpen(true);
                        }}
                      >
                        New Project
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? '' : 'text-black/[.6]'
                    } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                    onClick={() => {
                      setIsFeedbackSurveyPopupOpen(true);
                    }}
                  >
                    Feedback
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? '' : 'text-black/[.6]'
                    } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                    data-cal-link="checklist/book-a-demo"
                  >
                    Book a demo
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    href="https://app.loopedin.io/checklistgg"
                    target={'_blank'}
                    rel="noreferrer"
                    className={`${
                      active ? '' : 'text-black/[.6]'
                    } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                  >
                    Roadmap ↗
                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? '' : 'text-black/[.6]'
                    } group flex w-full items-center font-normal rounded-md px-2 py-2 hover:bg-slate-100`}
                    onClick={signOut}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <FeedbackSurveyPopupComponent
        isOpen={isFeedbackSurveyPopupOpen}
        setOpen={setIsFeedbackSurveyPopupOpen}
        orgId={selectedOrganization?.id}
        email={userData?.email}
      ></FeedbackSurveyPopupComponent>

      <CreateProjectPopupComponent
        isOpen={isCreateProjectPopupOpen}
        setIsOpen={setIsCreateProjectPopupOpen}
      />

      <PlanAndBillingPopupComponent
        isOpen={isPlanAndBillingPopupOpen}
        setIsOpen={setIsPlanAndBillingPopupOpen}
      ></PlanAndBillingPopupComponent>
    </div>
  );
}
