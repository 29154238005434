const CommonConstants = {
    API_PREFIX: (process.env.NEXT_PUBLIC_PRODUCTION === 'true' ? process.env.NEXT_PUBLIC_API_PREFIX : 'http://localhost:8080'),
    AGENT_API_PREFIX: process.env.NEXT_PUBLIC_AGENT_API_PREFIX || 'http://127.0.0.1:8000',
    SEARCH_API_PREFIX: process.env.NEXT_PUBLIC_SEARCH_API_PREFIX || 'http://000000000:8000',

    FREE_PLAN_MEMBERS_COUNT: 5,

    DEFAULT_COLORS: [
        '#F76E11', // Vibrant Orange
        '#FF9F1C', // Bright Amber
        '#F4D06F', // Mustard Yellow
        '#2EC4B6', // Aqua Green
        '#3D5A80', // Deep Blue
        '#EE6C4D', // Coral
        '#6A4C93', // Deep Purple
        '#9B5DE5', // Lavender Purple
        '#FF6F61', // Soft Red
        '#118AB2' // Ocean Blue
    ]
};

export default CommonConstants;
