import PERMISSIONS_CONSTANTS from '@/constants/permission-constants';
import ROLES_CONSTANTS from '@/constants/roles-constants';
import {
  GET_ORG_USERS_QUERY,
  INVITE_MEMBERS_TO_ORGANIZATION_AND_PROJECT_MUTATION
} from '@/graphql/organization.gql';
import { CREATE_PROJECT_WITH_MEMBERS_MUTATION } from '@/graphql/projects.gql';
import { isValidEmail } from '@/services/utils/utils.service';
import { useErrorStore } from '@/stores/error.store';
import { useOrganizationStore } from '@/stores/organization.store';
import { useProjectsStore } from '@/stores/projects.store';
import { OrgInvite, OrgUser } from '@/types/organization.type';
import { Project, ProjectInvite, ProjectUser } from '@/types/projects.type';
import { User } from '@/types/user.type';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { useUserId } from '@nhost/nextjs';
import { useRouter } from 'next/router';
import {
  Dispatch,
  Fragment,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import {
  CLPrimaryButton,
  CLPrimaryInput,
  CLPrimarySelect,
  CLSecondaryButton
} from '../ui-controls';
import CLButton from '../ui-controls/default-ui-controls/button';
import { LoadingSpinWhiteCustom } from '../utils/loading.component';
import CLTooltip from '../utils/tooltip';
import PlanAndBillingPopupComponent from '../utils/plan-and-billing-popup.component';

interface PopupProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function CreateProjectPopupComponent({ ...props }: PopupProps) {
  const router = useRouter();
  const userId = useUserId();

  // State
  const [projectName, setProjectName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isProjectLoading, setProjectLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [query, setQuery] = useState<string>('');
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [isPlanAndBillingPopupOpen, setIsPlanAndBillingPopupOpen] =
  useState<boolean>(false);

  // Store
  const [addProject] = useProjectsStore(s => [s.addProject], shallow);
  const { selectedOrganization, organizationPermissions } =
    useOrganizationStore();
  const { open: showError } = useErrorStore();
  const [projects] = useProjectsStore(s => [s.projects], shallow);

  // GraphQL
  const {
    loading: orgUserDataLoading,
    error,
    data: orgUsersData
  } = useQuery(GET_ORG_USERS_QUERY, {
    variables: { orgId: selectedOrganization?.id },
    fetchPolicy: 'network-only'
  });
  const [createProjectWithMembersMutation] = useMutation(
    CREATE_PROJECT_WITH_MEMBERS_MUTATION
  );
  const [inviteAMemberToOrganizationAndProjectMutation] = useMutation(
    INVITE_MEMBERS_TO_ORGANIZATION_AND_PROJECT_MUTATION
  );

  useEffect(() => {
    if (!orgUserDataLoading && orgUsersData) {
      prepareOrgUsersForDropdown(orgUsersData?.org_users);
    }
  }, [orgUserDataLoading, orgUsersData]);

  useEffect(() => {
    // Reset fields
    if (props.isOpen) {
      setProjectName('');
      setDescription('');

      setQuery('');
      setSelectedUsers([]);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (projects?.length > 0) {
      checkProjectLimitation();
    }
  }, [projects]);

  function checkProjectLimitation() {
    if (
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_plan_type?.is_freeplan &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations.length > 0
    ) {
      const memberChecklistLimitation =
        selectedOrganization?.pricing_subscription?.pricing_plan?.pricing_limitations.find(
          limitation => limitation.type === 'projects'
        );

      if (
        memberChecklistLimitation &&
        memberChecklistLimitation?.value &&
        projects?.length >= parseInt(memberChecklistLimitation?.value)
      ) {
        setShowUpgrade(true);
      }
    }
  }

  function prepareOrgUsersForDropdown(orgUsers: OrgUser[]) {
    if (orgUsers?.length > 0) {
      // Remove current user
      const filteredOrgUsers = orgUsers.filter(
        orgUser => orgUser?.user?.id !== userId
      );

      setUsers([
        ...filteredOrgUsers.map(orgUser => (orgUser.user ? orgUser.user : {}))
      ]);
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      createProject();
    }
  };

  function validate(): string | undefined {
    if (!projectName) {
      return 'Please enter project name';
    }

    // Validate all invited new users has valid email
    if (selectedUsers?.length > 0) {
      const invitedNewUsers = selectedUsers.filter(
        selectedUser => selectedUser?.id == selectedUser?.displayName
      );

      // Check if any new user is not valid email
      if (invitedNewUsers.length > 0) {
        const invalidNewUsers = invitedNewUsers.filter(invitedUser => {
          if (invitedUser?.id && !isValidEmail(invitedUser?.id)) {
            return invitedUser;
          }
        });

        if (invalidNewUsers.length > 0) {
          return 'Please enter valid email address';
        }
      }
    }
  }

  function getInsertOrgAndProjectInvites(projectId: string) {
    const orgInvites: OrgInvite[] = [];
    const projectInvites: ProjectInvite[] = [];

    const newUsers = selectedUsers?.filter(
      selectedUser => selectedUser?.id == selectedUser?.displayName
    );

    if (newUsers?.length > 0) {
      newUsers.forEach(_user => {
        orgInvites.push({
          org_id: selectedOrganization?.id as string,
          email: _user?.id,
          custom_role_id: ROLES_CONSTANTS.ORGANIZATION.MEMBER
        });

        projectInvites.push({
          org_id: selectedOrganization?.id as string,
          project_id: projectId,
          email: _user?.id,
          custom_role_id: ROLES_CONSTANTS.PROJECT.MEMBER
        });
      });
    }

    return { orgInvites, projectInvites };
  }

  async function createProject() {
    // Start create loading
    setProjectLoading(true);

    // Reset error message
    setErrorMessage('');

    // Do validation
    const validationError = validate();
    if (validationError) {
      // Show error message
      setErrorMessage(validationError);

      setProjectLoading(false);

      return;
    }

    // Generate project Id
    const newProjectId = uuidv4();

    // Create a new project
    const newProject: Project = {
      id: newProjectId,
      org_id: selectedOrganization?.id as string,
      name: projectName,
      description: description
    };

    // Get project existing members
    const projectUsers: ProjectUser[] = [];
    // Add current user to project
    projectUsers.push({
      project_id: newProjectId,
      user_id: userId,
      custom_role_id: ROLES_CONSTANTS.PROJECT.ADMIN
    });
    // Add invited members to project
    if (selectedUsers && selectedUsers?.length > 0) {
      const existingOrgUsers = selectedUsers?.filter(
        selectedUser => selectedUser?.id !== selectedUser?.displayName
      );

      if (existingOrgUsers && existingOrgUsers?.length > 0) {
        existingOrgUsers.forEach(existingUser => {
          projectUsers.push({
            project_id: newProjectId,
            user_id: existingUser?.id,
            custom_role_id: ROLES_CONSTANTS.PROJECT.MEMBER
          });
        });
      }
    }

    // Get project invite members
    let newOrgInvites: OrgInvite[] = [];
    let newProjectInvites: ProjectInvite[] = [];
    if (selectedUsers && selectedUsers?.length > 0) {
      // Get all new users - org_invites, project_invites
      const { orgInvites, projectInvites } =
        getInsertOrgAndProjectInvites(newProjectId);

      newOrgInvites = orgInvites;
      newProjectInvites = projectInvites;
    }

    // Create a project with members
    const { data: projectDetails, extensions: projectExtentions } =
      await createProjectWithMembersMutation({
        variables: {
          projects: [newProject],
          projectUsers: projectUsers
        }
      });

    // Invite a members to org and project
    if (newOrgInvites?.length > 0 && newProjectInvites?.length > 0) {
      try {
        const { data: result, extensions: _projectExtentions } =
          await inviteAMemberToOrganizationAndProjectMutation({
            variables: {
              orgInvites: newOrgInvites,
              projectInvites: newProjectInvites
            }
          });
      } catch (error) {
        if (error instanceof ApolloError) {
          showError(error.message);
        }
      }
    }

    // Append to projects store
    addProject({
      id: newProjectId,
      name: projectName
    } as Project);

    // Close popup
    props.setIsOpen(false);

    // Stop create loading
    setProjectLoading(false);

    router.push('/projects/' + newProjectId);
  }

  const filteredOptions = users.filter(option => {
    // If it is already added, don't consider for displayName matching
    const alreadySelected = selectedUsers.find(
      _selectedUser => _selectedUser?.id == option?.id
    );
    return (
      !alreadySelected &&
      option?.displayName?.toLowerCase().includes(query.trim().toLowerCase())
    );
  });

  const combinedOptions =
    query && filteredOptions.length == 0
      ? [
          ...users,
          {
            id: query,
            displayName: query
          }
        ]
      : users;

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            props.setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Header */}
                  <div className="flex justify-between items-center">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Add new project
                      </Dialog.Title>
                    </div>

                    <div>
                      <CLButton
                        className="p-2"
                        tabIndex={-1}
                        onClick={() => {
                          props.setIsOpen(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                            fill="black"
                            fillOpacity="0.5"
                          />
                        </svg>
                      </CLButton>
                    </div>
                  </div>

                  <div className="mt-4">
                    <CLPrimaryInput
                      type="text"
                      label="Project Name *"
                      value={projectName}
                      onKeyDown={handleKeyDown}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setProjectName(event.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="mt-4">
                    <CLPrimaryTextarea
                      label="Description"
                      value={description}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setDescription(event.target.value);
                      }}
                    />
                  </div> */}

                  {/* Invite project members */}
                  <div className="flex-1 text-sm mt-4">
                    <CLPrimarySelect
                      label="Project Members"
                      placeholder="Name or Email"
                      isMulti={true}
                      value={selectedUsers}
                      options={combinedOptions}
                      getOptionLabel={option => option?.displayName || ''}
                      getOptionValue={option => option.id as string}
                      inputValue={query}
                      onInputChange={value => {
                        setQuery(value);
                      }}
                      onChange={(selectedMembers, action) => {
                        if (Array.isArray(selectedMembers)) {
                          setSelectedUsers([...selectedMembers]);
                        }
                      }}
                      onBlur={() => {
                        // Ensure the input value remains in the text box
                        if (
                          query &&
                          !selectedUsers.some(
                            user =>
                              user.displayName === query || user.id === query
                          )
                        ) {
                          const newOption = {
                            id: query,
                            displayName: query
                          };
                          setSelectedUsers(prev => [...prev, newOption]);
                        }
                      }}
                      isOptionDisabled={option => {
                        if (
                          option.id &&
                          option?.id == option?.displayName &&
                          (!isValidEmail(option?.id) ||
                            !organizationPermissions?.[
                              PERMISSIONS_CONSTANTS.MANAGE_ORGANIZATION_MEMBERS
                            ])
                        ) {
                          return true;
                        }

                        return false;
                      }}
                      noOptionsMessage={() =>
                        'Please enter a email address to invite'
                      }
                    />
                  </div>

                  {/* Note */}
                  <div className="text-xs opacity-50 mt-4">
                    Note: Projects are private spaces, accessible only to
                    designated project members. To add someone, select from
                    existing organization members or invite new ones by entering
                    a valid email address.
                  </div>

                  {/* Footer */}
                  <div className="mt-4 flex items-center justify-between">
                    <div>
                      {errorMessage && (
                        <div className="text-red-400">{errorMessage}</div>
                      )}
                    </div>

                    <div className="flex gap-2">
                      <CLSecondaryButton
                        onClick={() => {
                          props.setIsOpen(false);
                        }}
                      >
                        Cancel
                      </CLSecondaryButton>

                      {showUpgrade ? (
                        <CLTooltip content="Upgrade to Create Project">
                          <CLPrimaryButton
                            onClick={() => {
                              setIsPlanAndBillingPopupOpen(true)
                            }}
                            disabled={isProjectLoading}
                          >
                            {isProjectLoading && (
                              <LoadingSpinWhiteCustom className="w-4 h-4"></LoadingSpinWhiteCustom>
                            )}
                            Create
                          </CLPrimaryButton>
                        </CLTooltip>
                      ) : (
                        <CLPrimaryButton
                          onClick={() => {
                            createProject();
                          }}
                          disabled={isProjectLoading}
                        >
                          {isProjectLoading && (
                            <LoadingSpinWhiteCustom className="w-4 h-4"></LoadingSpinWhiteCustom>
                          )}
                          Create
                        </CLPrimaryButton>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <PlanAndBillingPopupComponent
        isOpen={isPlanAndBillingPopupOpen}
        setIsOpen={setIsPlanAndBillingPopupOpen}
      ></PlanAndBillingPopupComponent>
    </>
  );
}
